export const PageLinks = {
    HOME: "/",
    CATEGORY: '/cat',
    PRODUCT_DETAILS: '/product',
    PRODUCT_CUSTOMIZE: '/product-customize',
    CART: '/cart',
    CHECKOUT: '/checkout',
    CHECKOUT_RESPONSE: '/checkout-response',
    MY_ACCOUNT: '/my-account',
    MY_ORDERS: '/my-orders',
    MY_PROFILE: '/my-profile',
    MY_ADDRESS: '/my-address',
    CHANGE_PASSWORD: '/change-password',
    MY_WISHLIST: '/my-wishlist',
    HELP_CENTER: '/help-center',
    ABOUT: '/about-us',
    SHIPPING: '/shipping-policy',
    TERMS_CONDITIONS: '/terms-conditions',
    PRIVACY_POLICY: '/privacy-policy',
    BLOG: '/blog',
    LOGIN: "/authentication",
    CONTACT: '/contact',
    ORDER_DETAILS: '/order-details',
    TESTIMONIALS: '/testimonials',
    FORGOTPASSWORD: '/forgotpassword',
}

export const EndPoints = {
    InitAnonymousUser: "init_anonymous_user",
    Home: "home",
    MenuCategories: 'menu_categories',
    Countries: 'countries',
    States: 'states',
    Products: "products",
    ProductCustomSize: "product_custom_size",
    HighlightItems: "highlight_items",
    CartCount: "cart_count",
    CartItems: "cart_items",
    AddCartItemAddon: "add_cart_item_addon",
    CustomerRegister: "customer_register",
    CustomerLogin: "customer_login",
    CustomerProfile: "customer_profile",
    CustomerPasswordUpdate: "customer_password_update",
    WishlistItems: "wishlist_items",
    CustomerAddresses: "customer_addresses",
    PaymentMethods: "payment_Methods",
    Checkout: "checkout",
    OrderGroups: "order_groups",
    Orders: "orders",
    SearchSuggestions: "search_suggestions",
    ContactUs: "contact_us",
    Testimonials: "testimonials",
    DeliveryCheck: "delivery_check",
}

export const Configs = {
    //BaseUrl: "http://www.venetz.local/api/",
    BaseUrl: 'https://admin.venetz.in/api/',
    ToastDuration: 2000,
    DeliveryAmount: 800,
};

export const DateFormats = {
    DDMMYYYY: 'DD/MM/YYYY',
    DDMONYYYY: 'DD MMM YYYY',
    DDMONTHYYYY: 'DD MMMM YYYY',
    YYYYMMDD: 'YYYY-MM-DD'
}

export const ProductPricingType = {
    ProductBased: 1,
    AttributeBased: 2,
    VariantBased: 3
}

export const ProductImageType = {
    ProductBased: 1,
    AttributeBased: 2,
    VariantBased: 3
}

export const AttributeTypeDisplayType = {
    Text: 1,
    ColorSwatch: 2,
    ImageSwatch: 3
}

export const PaymentTerm = {
    Prepaid: 1,
    Postpaid: 2
}

export const PaymentMethods = {
    COD: 1,
    PayU: 2,
}

export const OrderStatuses = {
    Failed: 10,
    Cancelled: 15,
    Pending: 20,
    ConfirmationPending: 25,
    Confirmed: 30,
    Packed: 40,
    Shipped: 50,
    Delivered: 60,
    Completed: 70
}

export const SearchSuggestionTypes = {
    Category: 1,
    Product: 2
}

export const HighlightTypes = {
    PopularProducts: 4
}

export type AlertType = 'error' | 'info' | 'success' | 'warning';