import { IonContent, IonHeader, IonPage, IonRouterLink } from '@ionic/react';
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { PageLinks } from '../common/Constants';

import { Helmet } from 'react-helmet';

const TermsAndConditions: React.FC = () => {

    return (
        <>
            <Helmet>‍
                <title>Customized Ready to install Mosquito nets with frames | Terms and Conditions</title>
                <meta name='title' content='Customized Ready to install Mosquito nets with frames' />
                <meta property='og:url' content='https://www.venetz.in/terms-conditions' />
                <meta property='og:type' content='website' />
                <meta property='og:title' content='VENETZ | Terms and Conditions' />
                <meta property='og:description' content='VENETZ | Terms and Conditions' />
                <meta property='og:site_name' content='VENETZ' />
            </Helmet>
            <IonPage>
                {/* <Menu /> */}
                <IonHeader>
                    <Header />
                </IonHeader>
                <IonContent fullscreen id="main-content">
                    <section className="page-banner">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <span className="round-shape"></span>
                                    <h2 className="banner-title">Terms & Conditions</h2>
                                    <div className="bread-crumb"><IonRouterLink routerLink={PageLinks.HOME} className='bread-crumb-text'>Home</IonRouterLink> / Terms & Conditions</div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="popular-section white_bg">
                        <div className="shape-round">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h2 className="sec-title">WELCOME TO VENETZ!</h2>
                                    <div className="testimonials-content">
                                        <p className='about_content'>The portal, venetz.in (“Website”) is managed and operated by Venetz enables sale and purchase of diverse range of products listed on the Platform from time to time (“Services”). TERMS AND CONDITIONS FOR USE OF THE PLATFORM</p>
                                        <p className='about_content'>Any person who access and/or use the Platform in any manner (referred as ”you”, “your”), shall be subject to these terms and conditions for use and be bound by the policies formulated by Venetz</p>
                                        <p className='about_content'>These terms and conditions for use, the policies as list above, and such other policies or terms as may be prescribed by Venetz (“Terms”), together constitute a legally binding agreement between the You and Venetz, in connection with the access to or use of the Platform. You are requested to read these Terms carefully before accessing or using the Platform</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 mt-20">
                                    <h2 className="sec-title">COMMUNICATION AND UNSUBSCRIPTION</h2>
                                    <div className="testimonials-content">
                                        <p className='about_content'>You hereby understands that with your registration onto the Platform, you may receive notifications from Venetz in the form of SMS messages on their registered mobile number or e-mails. These notifications could relate to their registration, transactions that they carry out through the Platform and/or promotions. Further, Venetz may also send notifications and reminders to them with respect to their activity on the Platform, in relation to the Services. Please note that while Venetz endeavors to provide these notifications and reminders to you, Venetz does not provide any guarantee and shall not be held liable or responsible for any failure to send such notifications or reminders to you. The User can unsubscribe / opt-out from receiving communications, newsletters and other notifications from Venetz at any time by following the procedure set forth on the Platform.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 mt-20">
                                    <h2 className="sec-title">TERM AND TERMINATION</h2>
                                    <div className="testimonials-content">
                                        <p className='about_content'>These Terms shall be effective from the date of their publication on the Platform.</p>
                                        <p className='about_content'>Venetz reserves the right to suspend or terminate its Services in the event of breach of any terms contained in these Terms, misrepresentation of information, any unlawful activity by you or if Venetz is unable to verify or authenticate any information submitted by you.</p>
                                        <p className='about_content'>You may terminate the Agreement at any time by discontinuing the use of the Platforms or Services. By terminating the usage or the Agreement you will be obligated to pay for a Product purchased on the Platforms, or any other obligation which has accrued, or is unfulfilled and relates to the period, prior to termination.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 mt-20">
                                    <h2 className="sec-title">WAIVER</h2>
                                    <div className="testimonials-content">
                                        <p className='about_content'>No provision in these Terms will be deemed waived and no breach excused, unless such waiver or consent is in writing and signed by Venetz. Any consent by Venetz to, or waiver of a breach by the User, whether expressed or implied, will not constitute consent to, waiver of, or excuse for any other different or subsequent breach.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </IonContent>
            </IonPage>
        </>
    );
};
export default TermsAndConditions;
