import { IonContent, IonHeader, IonPage, IonRouterLink } from '@ionic/react';
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { PageLinks } from '../common/Constants';

import { Helmet } from 'react-helmet';

const ShippingPolicy: React.FC = () => {

    return (
        <>
            <Helmet>‍
                <title>Customized Ready to install Mosquito nets with frames | Shipping Policy</title>
                <meta name='title' content='Customized Ready to install Mosquito nets with frames' />
                <meta property='og:url' content='https://www.venetz.in/shipping-policy' />
                <meta property='og:type' content='website' />
                <meta property='og:title' content='VENETZ | Shipping Policy' />
                <meta property='og:description' content='VENETZ | Shipping Policy' />
                <meta property='og:site_name' content='VENETZ' />
            </Helmet>
            <IonPage>
                {/* <Menu /> */}
                <IonHeader>
                    <Header />
                </IonHeader>
                <IonContent fullscreen id="main-content">
                    <section className="page-banner">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <span className="round-shape"></span>
                                    <h2 className="banner-title">Shipping & Return Policy</h2>
                                    <div className="bread-crumb"><IonRouterLink routerLink={PageLinks.HOME} className='bread-crumb-text'>Home</IonRouterLink> / Shipping & Return Policy</div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="popular-section white_bg">
                        <div className="shape-round">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h2 className="sec-title">SHIPPING & RETURN POLICY</h2>
                                    <div className="testimonials-content">
                                        <p className='about_content'>Any product you return must be in the same condition you received it and in the original packaging. Please keep the receipt.</p>
                                        <p className='about_content'>In the unlikely event that any merchandise you have ordered from us is not received in good condition, is damaged or defective, or if merchandise delivered is different from what you had expected, you may return the merchandise unused, in its original packaging and tags.</p>
                                        <p className='about_content'>Any exchange or returns for reasons other than the above, the shipping charges if any, will not be refunded.</p>
                                        <p className='about_content'>Before accepting delivery of any merchandise, please ensure, that the packaging has not been damaged or tampered with. If you believe that the merchandise is not in good condition, or if the packaging is tampered with or damaged, before accepting delivery of the goods, please refuse to take delivery of the package, and write to us at info@venetz.in, mentioning your order reference number. We shall make our best efforts to ensure that a replacement delivery is made to you at the earliest.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 mt-20">
                                    <h2 className="sec-title">THE EXTERNAL PACKAGING WAS DAMAGED</h2>
                                    <div className="testimonials-content">
                                        <p className='about_content'>At the time of delivery if you notice that the external packing of the consignment is damaged or tampered with, please do not accept the package. Instead, add your remarks on the courier delivery sheet and raise a complaint with us immediately, citing your order details. We will resolve your issue with the respective courier partner.</p>
                                        <p className='about_content'>Once our courier partner updates that your parcel is accepted for return and updates this on their website, we will start your refund process..</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </IonContent>
            </IonPage>
        </>
    );
};
export default ShippingPolicy;
