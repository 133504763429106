import React, { useEffect, useState } from 'react';
import { IonContent, IonHeader, IonPage } from '@ionic/react';
import './Page.css';

import { Helmet } from 'react-helmet';


import Header from '../components/Header';
import HomeSlider from '../components/HomeElements/HomeSlider';
import HomeProductCategories from '../components/HomeElements/Home-product-categories';
import ProductDetailSection from '../components/HomeElements/ProductDetailSection';
import HomeHowToMeasure from '../components/HomeElements/HomeHowToMeasure';
import MostPopular from '../components/HomeElements/MostPopular';
import TestimonialSection from '../components/HomeElements/TestimonialSection';
import Footer from '../components/Footer';
import Menu from '../components/Menu';
import { APIData } from '../common/DataTypes';
import * as ServerAPI from '../common/ServerAPI';
import { useAppStateAPI } from '../common/AppStateAPI';
import { PageLinks } from '../common/Constants';
import { useLocation } from 'react-router';
import { url } from 'inspector';

const Home: React.FC = () => {

  const location = useLocation();
  const { showToastMsg, addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();

  const [homeData, setHomeData] = useState<APIData.Home>();

  useEffect(() => {
    if (location.pathname === PageLinks.HOME) {
      fetchHomeData();
    }
  }, [location.pathname]);

  const fetchHomeData = () => {
    addProcessingRequests();
    ServerAPI.getHome().then((response) => {
      if (response.success) {
        setHomeData(response.customer_home);
      } else if (response.message) {
        showToastMsg('error', response.message);
      } else {
        showToastMsg('error', 'Error occurred while processing the request');
      }
    }).finally(() => {
      reduceProcessingRequests();
    });
  }

  const addToWishlist = (productID: number) => {
    addProcessingRequests();
    ServerAPI.addItemToWishlist(productID).then((response) => {
      if (homeData !== undefined && response.success) {
        setHomeData({
          ...homeData, highlight_products: homeData.highlight_products.map(product => {
            if (product.product_id === productID) {
              return { ...product, wishlist_item_id: response.wishlist_item_id };
            } else {
              return product;
            }
          })
        });
        showToastMsg('success', response.message);
      } else if (response.message) {
        showToastMsg('error', response.message);
      } else {
        showToastMsg('error', 'Error occurred while processing the request');
      }
    }).finally(() => {
      reduceProcessingRequests();
    });
  }

  const removeFromWishlist = (wishlistItemID: number) => {
    addProcessingRequests();
    ServerAPI.removeItemFromWishlist(wishlistItemID).then((response) => {
      if (homeData !== undefined && response.success) {
        setHomeData({
          ...homeData, highlight_products: homeData.highlight_products.map(product => {
            if (product.wishlist_item_id === wishlistItemID) {
              return { ...product, wishlist_item_id: 0 };
            } else {
              return product;
            }
          })
        });
        showToastMsg('success', response.message);
      } else if (response.message) {
        showToastMsg('error', response.message);
      } else {
        showToastMsg('error', 'Error occurred while processing the request');
      }
    }).finally(() => {
      reduceProcessingRequests();
    });
  }

  return (
    <>‍
      <Helmet>‍
        <title>Customized Ready to install Mosquito nets with frames</title>
        <meta name='title' content='Customized Ready to install Mosquito nets with frames' />
        <meta property='og:url' content='https://www.venetz.in/' />
        <meta property='og:type' content='website' />
        <meta property='og:title' content='VENETZ' />
        <meta property='og:description' content='VENETZ' />
        <meta property='og:site_name' content='VENETZ' />
      </Helmet>
      <IonPage>
        {/* <Menu /> */}
        <IonHeader>
          <Header />
        </IonHeader>
        <IonContent fullscreen id="main-content">
          {homeData !== undefined && (
            <>
              <HomeSlider sliderImages={homeData.dynamic_images} />
              <HomeProductCategories />
              {homeData.highlight_products !== undefined && homeData.highlight_products.map((product, index) => (
                <ProductDetailSection productDetail={product} key={index} addToWishlist={addToWishlist} removeFromWishlist={removeFromWishlist} showAddOn={true} />
              ))}
              <HomeHowToMeasure />
              <MostPopular />
              <TestimonialSection />
              <Footer />
            </>
          )}
        </IonContent>
      </IonPage>
    </>
  );
};

export default Home;
